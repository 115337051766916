<script setup lang="ts">
const prepareImgUrl = useImage();
const imgUrl = (image: string) =>
	`url(${prepareImgUrl(`nuxt-img/wheel-of-fortune/${image}`, {
		format: "webp",
		quality: 100,
		width: 324,
		height: 155
	})})`;
const imgFrame = imgUrl("frame-1.png");
const imgFrame2 = imgUrl("frame-2.png");
</script>

<template>
	<div class="frame">
		<NuxtImg
			v-for="(leave, index) in 3"
			:key="leave + index"
			:class="`leaves leave-${index + 1}`"
			src="/nuxt-img/wheel-of-fortune/leaves.png"
			format="avif"
			width="232"
			height="232"
			alt="leaves"
			loading="lazy"
		/>
		<NuxtImg
			class="title"
			src="/nuxt-img/wheel-of-fortune/banner-logo-m.png"
			format="avif"
			width="389"
			height="199"
			alt="title"
			loading="lazy"
		/>
	</div>
</template>

<style lang="scss" scoped>
.frame {
	position: relative;
	margin: -80px auto 0;
	background: v-bind(imgFrame) no-repeat center center / contain;
	animation: border-lights 1000ms linear infinite;
	max-width: 400px;
	width: 100%;
	height: auto;
	.leaves {
		position: absolute;
		top: -40%;
		z-index: -1;
		width: 60%;
		height: auto;
		&.leave-1 {
			left: -2%;
			animation: leave-left 10s ease-in-out infinite;
		}
		&.leave-2 {
			right: -2%;
			transform: rotate(35deg);
			animation: leave-right 10s ease-in-out infinite;
		}
		&.leave-3 {
			left: 25%;
			top: -35%;
			transform: rotate(35deg);
			animation: leave-right 10s ease-in-out infinite;
		}
	}
	.title {
		position: relative;
		z-index: 1;
		top: 1.6em;
		width: 80%;
		height: auto;
		margin: 0 auto;
		display: block;
	}
}
@keyframes border-lights {
	50% {
		background: v-bind(imgFrame2) no-repeat center center / contain;
	}
}
@keyframes leave-left {
	25% {
		transform: scale(1.02) rotate(2deg);
	}
	75% {
		transform: translateY(3px) scale(0.98);
	}
}
@keyframes leave-right {
	35% {
		transform: scale(1.02) rotate(35deg);
	}
	85% {
		transform: translateY(2px) scale(0.98) rotate(32deg);
	}
}
</style>
